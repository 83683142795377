import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { device } from "../../styles/devices";

//podcast
import { carregaEpisodios } from "./podcast.actions";
import { buscaPodcast } from "./podcast.services";
import { ordenaEpisodiosPorData } from "./podcast.helpers";

//player
import { preparaEpisodio } from "../player/player.actions";

//controle de localstorage
import { loadStuff, saveStuff } from "../../localStorage";

import BarraControles from "./BarraControles";
import Episodio from "./Episodio";

const ListaWrapper = styled.ul`
  padding: 2rem 1rem;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

const EpisodioItem = styled.li`
  margin-bottom: 2rem;
  min-width: 25%;
  max-width: 25%;
  flex: 1;

  @media ${device.tablet} {
    max-width: 50%;
    min-width: 50%;
  }
`;

function ListaEpisodios(props) {
  /**
   * coloca um episodio no player do soundcloud
   * @param {object} episodio episodio a ser tocado
   * @param {bool} trocarUrl se a url vai ser alterada ou nao
   */
  const tocarEpisodio = (episodio, autoPlay = false, trocarUrl = true) => {
    const { preparaEpisodio } = props;

    if (trocarUrl) {
      //trocando a url
      props.history.push(`/${episodio.numero}`);
    }

    //salvando o que esta tocando em localstorage
    saveStuff("@amigavelmentepodcast:episodio_tocando", episodio);

    preparaEpisodio(episodio, autoPlay);
  };

  /**
   * busca todos os dados no feed
   */
  const carregaPodcast = () => {
    buscaPodcast().then(({ items: episodios }) => {
      //REMOVER SLICE - usado apenas para limitar a 10 o numero de episodios no podcast teste

      const episodiosOrdenados = ordenaEpisodiosPorData(episodios);
      console.log("📒 episodiosOrdenados", episodiosOrdenados);

      //preparando para tocar o mais recente
      let episodioParaTocar = episodiosOrdenados[0];

      //se um numero de podcast foi passado na url, vamos inserir ele no player
      if (props.tocarAoCarregar) {
        console.log("📣 episódio na url", props.tocarAoCarregar);

        episodioParaTocar =
          episodiosOrdenados.find(
            ({ numero }) => `${numero}` === props.tocarAoCarregar
          ) || episodiosOrdenados[0];
      }
      //se nao tiver um podcast na url e houver um salvo em localstorage, prepara ele
      else {
        console.log("‼️ sem episódio na url");

        const episodioEmLocalStorage = loadStuff(
          "@amigavelmentepodcast:episodio_tocando"
        );
        if (episodioEmLocalStorage) {
          console.log("📣 episódio em localStorage", episodioEmLocalStorage);

          //reprocurando na lista de episodios para garantir que ainda esta disponivel
          // episodioParaTocar =
          //   episodiosOrdenados.find(
          //     ({ numero }) => numero === episodioEmLocalStorage.numero
          //   ) || episodiosOrdenados[0];
        }

        //vamos usar o mais recente
        episodioParaTocar = episodiosOrdenados[0];
      }

      //colocando um episodio inicial no player -- da url, o mais recente ou o salvo em localstorage
      tocarEpisodio(episodioParaTocar, false, false);

      props.carregaEpisodios(episodiosOrdenados);
    });
  };

  /**
   * executado somente uma vez
   */
  useEffect(() => {
    //buscando podcasts
    carregaPodcast();
  }, []);

  return (
    <ListaWrapper>
      {props.episodios.map(episodio => {
        const estaTocando = episodio.numero === props.tocando.numero;

        return (
          <EpisodioItem key={episodio.guid}>
            <Episodio
              tocarEpisodio={tocarEpisodio}
              episodio={episodio}
              estaTocando={estaTocando}
              iframeStatus={props.iframeStatus}
            />
          </EpisodioItem>
        );
      })}
      <BarraControles />
    </ListaWrapper>
  );
}

ListaEpisodios.propTypes = {
  episodios: PropTypes.array,
  carregaEpisodios: PropTypes.func.isRequired,
  tocarAoCarregar: PropTypes.string,
  preparaEpisodio: PropTypes.func.isRequired,
  tocando: PropTypes.object,
  history: PropTypes.object.isRequired,
  iframeStatus: PropTypes.string
};

ListaEpisodios.defaultProps = {
  episodios: [],
  tocarAoCarregar: "",
  tocando: {},
  iframeStatus: null
};

const mapStateToProps = store => ({
  episodios: store.podcast.episodios,
  tocando: store.player.tocando,
  iframeStatus: store.player.iframeStatus
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ carregaEpisodios, preparaEpisodio }, dispatch);

//exportando com wrapper do redux e do router🔥
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListaEpisodios));
