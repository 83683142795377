export const marca = {
  vermelho1: "#d7431d",
  vermelho2: "#89202b",
  azul1: "#406092",
  azul2: "#406092",
  azulEscuro1: "#00183e"
};

//variaveis e tema principal do voucher
export const tema = {
  red: "#FF0000",
  black: "#393939",
  grey: "#3A3A3A",
  lightgrey: "#E1E1E1",
  lessLighterGrey: "#9e9e9e",
  offWhite: "#EDEDED",
  maxWidth: "1100px",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
  marcaCor1: "#d7431d",
  cores: {
    um: marca.vermelho1,
    dois: marca.azul1,
    tres: marca.azul2,
    quatro: marca.azulEscuro1
  }
};
