import {
  PREPARA_EPISODIO,
  ALTERA_STATUS_REPRODUCAO_IFRAME
} from "./player.actionTypes";

/**
 * insere um episodio como o que esta atualmente tocando
 * @param {object} episodio dados do episodio que esta em reproducao
 * @param {boolean} autoPlay se o episodio deve dar play assim que carregar
 */
export const preparaEpisodio = (episodio, autoPlay = false) => ({
  type: PREPARA_EPISODIO,
  episodio,
  autoPlay
});

/**
 * recebe de algum event listener no iframe a situacao atual de reproducao e insere na store
 * @param {string} iframeStatus status atual do player em formato de texto -- Ex: tocando, pausado
 */
export const alteraStatusReproducaoIframe = iframeStatus => ({
  type: ALTERA_STATUS_REPRODUCAO_IFRAME,
  iframeStatus
});
