import React from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import Icon from "../../modules/ui/Icon";

//push
import { inicializar } from "../../oneSignal";

//actions do contato
import { toggleModalContato } from "../../modules/contato/contato.actions";

const TopoStyled = styled.header`
  background-color: ${props => props.theme.cores.quatro};
  display: flex;
  padding-top: 3rem;
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Sobre = styled(Container)`
  display: flex;
  margin-bottom:150px;

  @media ${device.laptop} {
    margin-bottom:0;
  }

  @media ${device.tablet} {
    margin-bottom: 100px;
    flex-direction: column;
  }
`;

const SobreEsquerda = styled.div`
  color: #fff;
  p {
    margin-top: 2rem;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LogoCompleto = styled.img`
  @media ${device.tablet} {
    max-width: 36rem;
  }
  @media ${device.mobileL} {
    max-width: 85vw;
  }
`;

const ImagemDireita = styled.img`
  @media ${device.laptop} {
    max-height: 35rem;
    align-self: flex-end;
  }
  @media ${device.tablet} {
    max-height: 26rem;
    align-self: flex-end;
  }
`;

const LinksPlataforma = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;

  @media ${device.laptop} {
    margin-bottom: 1rem;
  }

  @media ${device.tablet} {
    align-self: flex-start;
    margin-bottom: 0;
  }
`;

const LinkPlataforma = styled.a`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0.6rem 1.1rem;
  background-color: #e3e6ec;
  color: ${props => props.theme.cores.quatro};
  margin-right: 0.4rem;
  margin-top: 0.4rem;
  transition: all 0.2s;
  cursor: pointer;
  span {
    font-size: 1.6rem;
    margin-left: 1rem;
  }

  &:hover {
    background-color: #fff;
  }
`;

const IconPlataforma = styled(Icon)`
  margin-top: -3px;
  fill: ${props => props.theme.cores.quatro};
`;

const Topo = props => {
  return (
    <TopoStyled>
      <Sobre>
        <SobreEsquerda>
          <Link to="/" title="Início">
            <LogoCompleto
              src="/img/amigavelmente-logo-completo.jpg"
              alt="Amigável MENTE Podcast"
            />
          </Link>
          <p>
            As habilidades socioemocionais são tendências para a vida escolar e
            para o futuro de jovens e crianças. O que são, como trabalhá-las e
            quais são os benefícios delas para educadores, familiares e alunos?{" "}
            <br /> Saiba tudo sobre o assunto na série de podcasts da solução
            didática Amigável MENTE, criada para desenvolver as competências
            socioemocionais de estudantes. A série é apresentada pelo psicólogo
            e embaixador da Amigável MENTE, Rossandro Klinjey.
          </p>
          <LinksPlataforma>
            <LinkPlataforma
              href="https://open.spotify.com/show/6udUGLxJqeOLsv6tzGCpXJ?si=H0N5Arg9ReKC55Ld_WT2FA"
              title="Ouça no Spotify"
              rel="external noopener"
              target="_blank"
            >
              <IconPlataforma icon="spotify" tamanho="1.8rem" />
              <span>
                Ouça no <strong>Spotify</strong>
              </span>
            </LinkPlataforma>
            <LinkPlataforma href="https://itunes.apple.com/br/podcast/podcast-amig%C3%A1vel-mente/id1455388876?l=en"
              title="Ouça no iTunes"
              rel="external noopener"
              target="_blank">
              <IconPlataforma icon="appleinc" tamanho="1.8rem" />
              <span>
                Ouça no <strong>iTunes</strong>
              </span>
            </LinkPlataforma>
            <LinkPlataforma
              href="http://feeds.soundcloud.com/users/soundcloud:users:595886844/sounds.rss"
              title="RSS"
              rel="external noopener"
              target="_blank"
            >
              <IconPlataforma icon="rss" tamanho="1.8rem" />
              <span>
                <strong>RSS</strong>
              </span>
            </LinkPlataforma>
            {!!props.toggleModalContato && (
              <LinkPlataforma as="button" onClick={props.toggleModalContato}>
                <IconPlataforma icon="envelope" tamanho="1.8rem" />
                <span>
                  Receba por <strong>e-mail</strong>
                </span>
              </LinkPlataforma>
            )}
            {false && (
              <LinkPlataforma as="span" onClick={inicializar}>
                <IconPlataforma icon="notification" tamanho="1.8rem" />
                <span>
                  Receba por <strong>notificação</strong>
                </span>
              </LinkPlataforma>
            )}
          </LinksPlataforma>
        </SobreEsquerda>
        {/* <ImagemDireita src="/img/rossandro.jpg" alt="Rossandro Klinjey" /> */}
      </Sobre>
    </TopoStyled>
  );
};

Topo.propTypes = {
  toggleModalContato: PropTypes.func
};

Topo.defaultProps = {
  toggleModalContato: null
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleModalContato }, dispatch);

//exportando com wrapper do redux e do router🔥
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topo);
