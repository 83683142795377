import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { sendPageView } from "../../GAListener";

import { toggleModalContato, ocultaModalContato } from "./contato.actions";
import { insereTagConversao, preparaLead } from "./contato.helpers";

import Icon from "../ui/Icon";
import ModalBasico from "../ui/ModalBasico";
import Formulario from "./Formulario";

const ContatoStyled = styled.div`
  margin-top: 1rem;
  padding-bottom: 4rem;
  @media ${device.mobileL} {
    padding-bottom: 1rem;
  }
`;

const Titulo = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${props => props.theme.cores.quatro};
`;

const Botoes = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Botao = styled.button`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0.6rem 1.1rem;
  background-color: ${props => props.theme.cores.dois};
  color: #fff;
  margin-right: 0.4rem;
  margin-top: 0.4rem;
  transition: all 0.2s;
  cursor: pointer;
  span {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
  &:not(&:first-of-type) {
    margin-left: 2rem;
  }

  &:hover {
    background-color: #e3e6ec;
    color: ${props => props.theme.cores.dois};
    svg {
      fill: ${props => props.theme.cores.dois};
    }
  }
`;

function Contato(props) {
  //controles do modal
  const [controlesModal, setControlesModal] = useState({
    estaCarregando: false,
    textoPrincipal: "Receba os episódios em seu e-mail! Preencha abaixo:",
    textoCorpo: "",
    icon: "",
    iconColor: "#000",
    ocultaChildren: false
  });

  //infos do lead
  const [lead, setLead] = useState({
    nome: "",
    email: ""
  });

  /**
   * altera os campos conforme o input do user
   * @param {object} event evento do listener
   */
  const onChangeLead = ({ currentTarget: { value, name } }) => {
    setLead({ ...lead, [name]: value });
  };

  const enviarLead = async e => {
    e.preventDefault();

    setControlesModal({
      ...controlesModal,
      estaAtivo: true,
      estaCarregando: true,
      textoPrincipal: "Enviando...",
      textoCorpo: "",
      ocultaChildren: false
    });

    //validando os campos
    if (!lead.nome || !lead.email) {
      setControlesModal({
        ...controlesModal,
        estaAtivo: true,
        estaCarregando: false,
        icon: "notification",
        textoPrincipal: "Ops!",
        textoCorpo: "Por favor, preencha todos os campos.",
        iconColor: "#d65245",
        ocultaChildren: false
      });

      return;
    }

    //vamos pegar os parametros da url
    const queryParams = queryString.parse(props.location.search);

    //preparando os dados
    const data = preparaLead({ ...lead, ...queryParams });
    console.log("data", data);
    try {
      const resposta = await axios.post(
        `${process.env.REACT_APP_API_URL}/leads`,
        data
      );
      console.log("resposta", resposta);

      if (resposta) {
        //mostrando mensagem de sucesso
        insereTagConversao();

        setControlesModal({
          ...controlesModal,
          estaAtivo: true,
          estaCarregando: false,
          icon: "checkmark",
          textoPrincipal: "Tudo certo!",
          textoCorpo:
            "Você receberá um aviso quando um novo episódio estiver disponível.",
          iconColor: "#42c613",
          ocultaChildren: true
        });

        //limpando o lead
        setLead({
          nome: "",
          email: ""
        });
      }

      //fazendo o redir para pageview
      sendPageView({
        pathname: "/sucesso",
        modificarNaBarraTitulo: "Sucesso"
      });
    } catch (error) {
      console.error(error);

      setControlesModal({
        ...controlesModal,
        estaAtivo: true,
        estaCarregando: false,
        icon: "notification",
        textoPrincipal: "Ops! Algo deu errado.",
        textoCorpo: "Por favor, tente novamente",
        iconColor: "#d65245",
        ocultaChildren: false
      });
    }
  };

  return (
    <ContatoStyled>
      <Titulo>Fique por dentro dos novos episódios!</Titulo>
      <Botoes>
        <Botao onClick={props.toggleModalContato}>
          <Icon icon="envelope" tamanho="1.8rem" />
          <span>
            Receba por <strong>e-mail</strong>
          </span>
        </Botao>
        {/* <Botao onClick={props.toggleModalContato}>
          <Icon icon="notification" tamanho="1.8rem" />
          <span>
            Receba por <strong>notificação</strong>
          </span>
        </Botao> */}
      </Botoes>
      <ModalBasico
        estaAtivo={props.estaMostrandoModal}
        textoPrincipal={controlesModal.textoPrincipal}
        textoCorpo={controlesModal.textoCorpo}
        icon={controlesModal.icon}
        iconCor={controlesModal.iconColor}
        fecharModal={props.ocultaModalContato}
        ocultarChildren={controlesModal.ocultaChildren}
      >
        <Formulario
          lead={lead}
          onChangeInput={onChangeLead}
          onSubmit={enviarLead}
        />
      </ModalBasico>
    </ContatoStyled>
  );
}

Contato.propTypes = {
  toggleModalContato: PropTypes.func,
  ocultaModalContato: PropTypes.func.isRequired,
  estaMostrandoModal: PropTypes.bool
};

Contato.defaultProps = {
  toggleModalContato: null,
  estaMostrandoModal: false
};

const mapStateToProps = store => ({
  estaMostrandoModal: store.contato.estaMostrandoModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleModalContato, ocultaModalContato }, dispatch);

//exportando com wrapper do redux e do router🔥
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Contato));
