import { combineReducers } from "redux";

import podcast from "./modules/podcast/podcast.reducer";
import player from "./modules/player/player.reducer";
import contato from "./modules/contato/contato.reducer";

const rootReducer = combineReducers({
  podcast,
  player,
  contato
});

export default rootReducer;
