import {
  PREPARA_EPISODIO,
  ALTERA_STATUS_REPRODUCAO_IFRAME
} from "./player.actionTypes";

const initialState = {
  tocando: null,
  autoPlay: false,
  iframeStatus: null
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PREPARA_EPISODIO:
      return {
        ...state,
        tocando: action.episodio,
        autoPlay: action.autoPlay
      };
    case ALTERA_STATUS_REPRODUCAO_IFRAME:
      return {
        ...state,
        iframeStatus: action.iframeStatus
      };
    default:
      return state;
  }
};

export default playerReducer;
