import React from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";

import Topo from "./Topo";
import Footer from "./Footer";
import ListaEpisodios from "../../modules/podcast/ListaEpisodios";
import Player from "../../modules/player/Player";
import Contato from "../../modules/contato/Contato";

const HomeStyled = styled.div``;

const Main = styled.main`
  @media ${device.laptop} {
    padding: 0 1rem;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const Home = ({
  match: {
    params: { episodio: episodioUrl }
  }
}) => (
  <HomeStyled>
    <Topo />
    <Main>
      <Container>
        <Player />
        <ListaEpisodios tocarAoCarregar={episodioUrl} />
        {/* <button onClick={togglePlayer}>Toggle teste</button> */}
        <Contato />
      </Container>
    </Main>
    <Footer />
  </HomeStyled>
);

export default Home;
