import React from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";

const FooterStyled = styled.div`
  @media ${device.laptop} {
    padding: 0 1rem;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
  padding-top: 3rem;
  margin-top: 3rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const Pearson = styled.div`
  display: flex;
  align-items: center;
  img {
    @media ${device.tablet} {
      max-width: 13rem;
    }
  }
  span {
    margin-left: 2rem;
  }
`;

const LinkP9 = styled.a`
  display: inline-block;
  @media ${device.tablet} {
    margin-top: 2rem;
  }
`;

const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <Pearson>
          <img
            src="/img/pearson-logo-fundo-branco.jpg"
            alt="Pearson Brasil - Amigável MENTE"
          />
          {/* <span>Pearson Brasil - Amigável MENTE</span> */}
        </Pearson>
        <LinkP9
          href="https://p9.digital/"
          title="Agência de marketing digital de performance"
          target="_blank"
          rel="external noopener"
        >
          Feito com ♥ por <strong>P9 Digital</strong>
        </LinkP9>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
