/**
 * insere a tag de conversao
 */
export const insereTagConversao = () => {
  return "nice";
  const scriptPrincipal = document.createElement("script");
  scriptPrincipal.onload = function() {
    console.log("Script principal de conversão ok, executando codigo");
    const scriptConversao = document.createElement("script");
    scriptConversao.src = "/conversion-tag-scripts/head.js";
    document.getElementsByTagName("head")[0].appendChild(scriptConversao);
  };
  scriptPrincipal.src =
    "https://www.googletagmanager.com/gtag/js?id=AW-761337673";
  document.getElementsByTagName("head")[0].appendChild(scriptPrincipal);
};

/**
 * corrige o problema de as vezes usar os parametros de ads com ou sem o prefixo 'utm_'
 * @param {object} lead os dados completos do lead ou um dataset somente com os a serem corrigidos
 */
export const corrigeAdsParams = lead => ({
  ...lead,
  source: lead.source || lead.utm_source,
  medium: lead.medium || lead.utm_medium,
  term: lead.term || lead.utm_term,
  campaign: lead.campaign || lead.utm_campaign,
  content: lead.content || lead.utm_content
});

/**
 * faz as transformacoes necessarias no corpo do lead antes do disparo
 * @param {object} lead os dados completos do lead
 */
export const preparaLead = lead => {
  let leadPreparado = corrigeAdsParams(lead);

  //adicionando identificacao da plataforma
  leadPreparado["ferramenta_codigo"] = "podcast";

  return {
    ...leadPreparado
  };
};
