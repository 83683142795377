import { TOGGLE_MODAL_CONTATO } from "./contato.actionTypes";

const initialState = {
  estaMostrandoModal: false
};

const contatoReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_CONTATO:
      return {
        ...state,
        estaMostrandoModal: action.novoEstado
      };
    default:
      return state;
  }
};

export default contatoReducer;
