import React, { Fragment } from "react";

import { ThemeProvider } from "styled-components";
import { GlobalStyled } from "./styles/global";
import { tema } from "./styles/tema";

import { Provider } from "react-redux";
import reduxStore from "./store";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GAListener from "./GAListener";
import Home from "./pages/home/Home";

const App = () => (
  <ThemeProvider theme={tema}>
    <Provider store={reduxStore}>
      <Fragment>
        <GlobalStyled />
        <Router>
          <GAListener>
            <Switch>
              <Route path="/:episodio?" component={Home} />
            </Switch>
          </GAListener>
        </Router>
      </Fragment>
    </Provider>
  </ThemeProvider>
);

export default App;
