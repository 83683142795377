import { TOGGLE_MODAL_CONTATO } from "./contato.actionTypes";

export const mostraModalContato = () => ({
  type: TOGGLE_MODAL_CONTATO,
  novoEstado: true
});

export const ocultaModalContato = () => ({
  type: TOGGLE_MODAL_CONTATO,
  novoEstado: false
});

export const toggleModalContato = () => {
  return (dispatch, getState) => {
    const {
      contato: { estaMostrandoModal }
    } = getState();

    dispatch({
      type: TOGGLE_MODAL_CONTATO,
      novoEstado: !estaMostrandoModal
    });
  };
};
