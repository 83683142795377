import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FormStyled = styled.form`
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 38rem;
`;

const InputStyled = styled.input`
  padding: 0.5rem 2rem;
  background-color: ${props => props.theme.lightgrey};
  border-radius: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  min-height: 4rem;
  margin: 0.5rem 0.5rem;
  flex: 1;
`;

const Submit = styled.button`
  background-color: ${props => props.theme.cores.dois};
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  margin: 1.4rem 0.5rem 0.5rem;
  transition: all 0.2s;
  padding: 0.6rem 2rem;
  align-self: center;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  &:focus {
    transform: translateY(0);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Formulario = ({ onSubmit, onChangeInput, lead }) => {
  return (
    <FormStyled onSubmit={onSubmit}>
      <InputStyled
        type="text"
        tabIndex={0}
        placeholder="Nome completo"
        name="nome"
        onChange={onChangeInput}
        value={lead.nome}
      />
      <InputStyled
        type="email"
        tabIndex={0}
        placeholder="E-mail"
        name="email"
        onChange={onChangeInput}
        value={lead.email}
      />
      <Submit type="submit">Me avise</Submit>
    </FormStyled>
  );
};

Formulario.propTypes = {
  lead: PropTypes.shape({
    nome: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Formulario;
