import moment from "moment";

/**
 * ordena os episodios do mais antigo para o mais novo
 * @param {array} episodios
 */
export const ordenaEpisodiosPorData = episodios =>
  episodios
    .sort((a, b) => (moment(a.pubDate).isBefore(b.pubDate, "minute") ? 1 : -1))
    .map((episodio, index) => ({
      ...episodio,
      numero: episodios.length - index,
      //adiciona um 0 na frente -- futuramente pode ser 00 tambem
      numeroComLeading:
        episodios.length - index < 10
          ? `0${episodios.length - index}`
          : `${episodios.length - index}`
    }));

/**
 * extrai somente o id de uma track de um guid
 * ex: tag:soundcloud,2010:tracks/478856514/blabla -> 478856514
 * @param {string} guid contem as tags e o id
 */
export const extraiTrackId = guid => {
  //pegando pela esquerda depois de tracks
  const final = guid.split("tracks/")[1];

  //removendo qualquer parametro na direita
  return final.split("/")[0];
};
