import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { device } from "../../styles/devices";
import moment from "moment";

import { connect } from "react-redux";
import Icon from "../ui/Icon";

const BarraStyled = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.2rem;
  background-color: white;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
`;

const ButtonAcaoWrapper = styled.button`
  background-color: ${props => props.theme.cores.quatro};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  align-self: stretch;
`;

const Titulo = styled.strong`
  margin-left: 1rem;
  @media ${device.tablet} {
    display: none;
  }
`;

const Legenda = styled.span`
  margin-left: 2rem;
`;

const Data = styled.span`
  margin-left: auto;
  margin-right: 2rem;
`;

function BarraControles(props) {
  /**
   * o wrapper na funcao global serve como um middleware
   */
  const onToggleReproducao = () => {
    if (window.iframeControl) {
      window.iframeControl.toggle();
    }
  };

  //somente mostrando se tiver episodio atual e tiver disponivel o status o iframe
  return !!props.tocando && !!props.iframeStatus ? (
    <BarraStyled>
      {!!props.iframeStatus && (
        <ButtonAcaoWrapper onClick={onToggleReproducao}>
          <Icon
            tamanho="2rem"
            icon={props.iframeStatus === "tocando" ? "pause2" : "play"}
          />
        </ButtonAcaoWrapper>
      )}
      <Legenda>Episódio #{props.tocando.numeroComLeading}</Legenda>
      <Titulo>{props.tocando.title}</Titulo>
      <Data>{moment(props.tocando.pubDate).format("LL")}</Data>
    </BarraStyled>
  ) : null;
}

BarraControles.propTypes = {
  tocando: PropTypes.object,
  iframeStatus: PropTypes.string
};

BarraControles.defaultProps = {
  iframeStatus: null
};

const mapStateToProps = store => ({
  tocando: store.player.tocando,
  iframeStatus: store.player.iframeStatus
});

export default connect(
  mapStateToProps,
  {}
)(BarraControles);
