/**
 * recupera em localstorage um objeto pela chave
 * @param {string} stuffKey chave para referenciar o objeto guardado
 */
export const loadStuff = stuffKey => {
  try {
    const stuffStringified = localStorage.getItem(stuffKey);
    if (stuffStringified === null) {
      return undefined;
    }

    return JSON.parse(stuffStringified);
  } catch (error) {
    console.log(`❌ Não foi possível recuperar em localStorage: ${stuffKey}`);
    return undefined;
  }
};

/**
 * coloca um valor em localStorage
 * @param {string} stuffKey chave para referenciar o objeto
 * @param {any} stuff valor a ser guardado
 */
export const saveStuff = (stuffKey, stuff) => {
  try {
    const stuffStringified = JSON.stringify(stuff);
    localStorage.setItem(stuffKey, stuffStringified);
  } catch (error) {
    console.log(`❌ Não foi possível salvar em localStorage: ${stuffKey}`);
  }
};

export const deleteStuff = stuffKey => {
	try {
		localStorage.removeItem(stuffKey);
		return null;
	} catch (error) {
		console.log(`❌ Não foi possível deletar em localStorage: ${stuffKey}`);
		return undefined;
	}
}