import React, { Component, createRef } from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";
import moment from "moment";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { deleteStuff } from "../../localStorage";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { alteraStatusReproducaoIframe } from "./player.actions";

import { extraiTrackId } from "../podcast/podcast.helpers";

//import PlayerLoadingSkeleton from "./PlayerLoadingSkeleton";
import LoadingCirculo from "../ui/LoadingCirculo";

const PlayerStyled = styled.div`
  padding-bottom: 5rem;
  margin-bottom: 5rem;
  border-bottom: 1px solid ${props => props.theme.lightgrey};
  @media ${device.mobileL} {
    padding-bottom: 3rem;
    margin-bottom: 2rem;
  }
`;

const IframePlayer = styled.iframe`
  margin-top: -10rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  @media ${device.laptop} {
    margin-top: 2rem;
  }
  @media ${device.tablet} {
    margin-top: -6rem;
  }
`;

const SkeletonWrapper = styled(IframePlayer)`
  background-color: #fff;
  height: 166px;
  position: relative;
  overflow: hidden;
`;

const LoadingFundo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SobreEpisodio = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-top: 4rem;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Principal = styled.div``;

const Legenda = styled.span`
  color: ${props => props.theme.lessLighterGrey};
  font-size: 1.4rem;
`;

const Titulo = styled.h2`
  font-weight: bold;
  font-size: 1.6rem;
`;

const Descricao = styled.p`
  color: #757575;
  margin-left: 2rem;

  @media ${device.tablet} {
    margin: 1rem auto 0;
  }
`;

class Player extends Component {
  constructor(props) {
    super(props);

    //criando ref do iframe para permitir controle
    this.iframePlayerRef = createRef();
  }

  onAlteraStatusReproducaoIframe = status => {
    const { alteraStatusReproducaoIframe } = this.props;
    if (alteraStatusReproducaoIframe) {
      alteraStatusReproducaoIframe(status);
    }
  };

  /**
   * limpar o localStorage do que esta atualmente sendo tocado e eventualmente realizar alguma acao com o user
   */
  onIframeFinalizado = () =>
    deleteStuff("@amigavelmentepodcast:episodio_tocando");

  /**
   * quando o iframe carregar com um episodio, precisamos atualizar os controles do player que sao inseridos globalmente no arquivo js-vendor/soundcloud.js
   */
  onIframeCarregado = () => {
    console.log("🛠 Node do player: ", this.iframePlayerRef.current);
    if (window.SC) {
      //vamos armazenar o controle globalmente -- somente por ser um iframe com DOM nao controlado pelo React
      window.iframeControl = window.SC.Widget(this.iframePlayerRef.current);

      //🧐 adicionando alguns listeners -- como o react mata o iframe anterior, nao vai ser necessario limpar os listener -- gracas a deus
      window.iframeControl.bind(window.SC.Widget.Events.READY, () => {
        console.log(`Player pronto, adicionando alguns listeners 🧐`);
        //bind do play
        window.iframeControl.bind(window.SC.Widget.Events.PLAY, () =>
          this.onAlteraStatusReproducaoIframe("tocando")
        );
        //bind do pause
        window.iframeControl.bind(window.SC.Widget.Events.PAUSE, () =>
          this.onAlteraStatusReproducaoIframe("pausado")
        );
        //bind do termino
        window.iframeControl.bind(
          window.SC.Widget.Events.FINISH,
          this.onIframeFinalizado
        );
      });
    }
  };

  render() {
    const { tocando, autoPlay } = this.props;

    const tituloDaPagina = tocando
      ? `Episódio #${tocando.numeroComLeading} | Podcast Amigável MENTE`
      : "Podcast Amigável MENTE | Programa Pearson Socioemocional";

    const description = tocando
      ? `Episódio #${tocando.numeroComLeading} - ${tocando.title}. Ouça agora.`
      : "Ouça a série de podcasts da Amigável MENTE, apresentada por Rossandro Klinjey, sobre a importância das habilidades socioemocionais.";

    return (
      <PlayerStyled>
        <Helmet>
          <title>{tituloDaPagina}</title>
          <meta property="og:title" content={tituloDaPagina} />
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
        </Helmet>
        {tocando ? (
          <IframePlayer
            title={tocando ? tocando.title : "Amigável MENTE Podcast"}
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            id="soundcloud-widget-player"
            ref={this.iframePlayerRef}
            onLoad={this.onIframeCarregado}
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${extraiTrackId(
              tocando.guid
            )}&color=%23406092&auto_play=${
              autoPlay ? "true" : "false"
            }&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false`}
          />
        ) : (
          <SkeletonWrapper as="div">
            <LoadingFundo>
              <LoadingCirculo cor="#406092" />
            </LoadingFundo>
          </SkeletonWrapper>
        )}
        {tocando && (
          <SobreEpisodio>
            <Principal>
              <Legenda>
                Episódio #{tocando.numeroComLeading} -{" "}
                {moment(tocando.pubDate).format("LL")}
              </Legenda>
              <Titulo>{tocando.title}</Titulo>
            </Principal>
            {tocando.content && <Descricao>{tocando.content}</Descricao>}
          </SobreEpisodio>
        )}
      </PlayerStyled>
    );
  }
}

Player.propTypes = {
  tocando: PropTypes.object,
  autoPlay: PropTypes.bool,
  alteraStatusReproducaoIframe: PropTypes.func
};

Player.defaultProps = {
  autoPlay: false,
  alteraStatusReproducaoIframe: null
};

const mapStateToProps = store => ({
  tocando: store.player.tocando,
  autoPlay: store.player.autoPlay
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ alteraStatusReproducaoIframe }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player);
