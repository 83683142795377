import { CARREGA_EPISODIOS } from "./podcast.actionTypes";

const initialState = {
  episodios: []
};

const episodiosReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARREGA_EPISODIOS:
      return {
        ...state,
        episodios: action.episodios
      };
    default:
      return state;
  }
};

export default episodiosReducer;
