import Parser from "rss-parser";

/**
 * funcao para fazer mock do delay de um retorno
 * @param {integer} stallTime tempo para aguardar
 */
async function stall(stallTime = 3000) {
  console.log(
    `⛔️ fazendo stalling de ${stallTime}ms - NAO ESQUECE ISSO LIGADO PLMDD`
  );
  await new Promise(resolve => setTimeout(resolve, stallTime));
}

export const buscaPodcast = async () => {
  const parser = new Parser();
  const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

  const feed = await parser.parseURL(
    CORS_PROXY +
      "https://feeds.soundcloud.com/users/soundcloud:users:595886844/sounds.rss"
  );

  //await stall(100000);

  return feed;
};
