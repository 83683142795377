import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { device } from "../../styles/devices";

import Icon from "../ui/Icon";

const EpisodioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    .play-icon {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
`;

const Topo = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: ${props => props.theme.cores.dois};
  border-radius: 15px;
  overflow: hidden;
  max-width: 20rem;
  box-shadow: ${props =>
    props.estaTocando ? "0px 0px 15px rgba(251, 142, 38, 1)" : "initial"};
  transition: all 0.2s;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const ImagemEpisodio = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const NumeroEpisodio = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1.6rem;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
`;

const Corpo = styled.div`
  padding: 1rem;
  @media ${device.mobileL} {
    text-align: center;
  }
`;

const Legenda = styled.span`
  color: ${props => props.theme.lessLighterGrey};
  font-size: 1.4rem;
  @media ${device.mobileL} {
    span {
      display: none;
    }
  }
`;

const Titulo = styled.h2`
  font-weight: bold;
  font-size: 1.6rem;
`;

const Play = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  transform: ${props =>
    props.estaTocando ? "translate(-50%, -50%)" : "translate(-50%, 500%)"};
  opacity: ${props => (props.estaTocando ? "1" : "0.4")};
  transition: all 0.3s;
`;

const Episodio = props => {
  const {
    episodio: { numeroComLeading, pubDate, title, itunes }
  } = props;

  return (
    <EpisodioWrapper
      onClick={() => {
        //se esse for o episodio atual, vamos fazer o toggle da reproducao
        if (props.estaTocando && window.iframeControl) {
          window.iframeControl.toggle();
        } else {
          props.tocarEpisodio(props.episodio, true, true);
        }
      }}
    >
      <Topo estaTocando={props.estaTocando}>
        {itunes && itunes.image && (
          <ImagemEpisodio src={itunes.image} alt={title} />
        )}
        {/* <NumeroEpisodio>
          <Icon icon="headphones" tamanho="2.5rem" /> {numeroComLeading}
        </NumeroEpisodio> */}
        <Play className="play-icon" estaTocando={props.estaTocando}>
          <Icon
            //se for o episodio atual e o status do iframe for tocando, altera o icone
            icon={
              props.estaTocando && props.iframeStatus === "tocando"
                ? "pause2"
                : "play"
            }
            tamanho="3.5rem"
            cor="#406092"
          />
        </Play>
      </Topo>
      <Corpo>
        <Legenda>
          <span>Episódio</span> #{numeroComLeading} -{" "}
          {moment(pubDate).format("L")}
        </Legenda>
        <Titulo>{title}</Titulo>
      </Corpo>
    </EpisodioWrapper>
  );
};

Episodio.propTypes = {
  episodio: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  tocarEpisodio: PropTypes.func.isRequired,
  estaTocando: PropTypes.bool,
  iframeStatus: PropTypes.string
};

Episodio.defaultProps = {
  estaTocando: false,
  iframeStatus: null
};

export default Episodio;
