import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LogoSVG = styled.svg`
  width: ${props => props.tamanho};
  height: ${props => props.tamanho};
  fill: ${props => props.cor};
`;

const Logo = props => (
  <LogoSVG {...props}>
    <use xlinkHref={`/icons/symbol-defs.svg#icon-${props.icon}`} />
  </LogoSVG>
);

Logo.propTypes = {
  colorido: PropTypes.bool,
  tamanho: PropTypes.string,
  cor: PropTypes.string,
  icon: PropTypes.string.isRequired
};

Logo.defaultProps = {
  colorido: false,
  tamanho: "6rem",
  cor: "#fff"
};

export default Logo;
